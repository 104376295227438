import { createTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';

// #cbb913

const rawTheme = createTheme({
  palette: {
    primary: {
      light: '#69696a',
      main: '#28282a',
      dark: '#1e1e1f',
    },
    secondary: {
      light: '#fff5f8',
      // main: '#ff3366',
      main: '#cbb913',
      // dark: '#e62958',
      dark: '#968900'
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: 'Muli',
    fontSize: 14,
    // fontWeightLight: 300, // Work Sans
    // fontWeightRegular: 400, // Work Sans
    // fontWeightMedium: 700, // Roboto Condensed
    fontWeightExtraBold: 800,
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h5: {
      ...rawTheme.typography.h5,
      fontWeight: rawTheme.typography.fontWeightBold,
      color: "#1c1c1b",
      fontSize: 20,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,

    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    },
  },
};

export default theme;
