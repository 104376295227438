import withRoot from "./modules/withRoot";
// --- Post bootstrap -----
import React from "react";
import AppAppBar from "./modules/views/AppAppBar";
import { Route, BrowserRouter, Switch, useLocation } from "react-router-dom";

import Helmet from "react-helmet";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./modules/theme";

function Index() {
    return (
        <React.Fragment>
            <SEO />
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <Switch>
                        <Route exact path="/" component={AppAppBar}></Route>
                        {/* <Route path="*">
                            <NoMatch />
                        </Route> */}
                    </Switch>
                </ThemeProvider>
            </BrowserRouter>
        </React.Fragment>
    );
}

function SEO() {
    return (
        <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
        </Helmet>
    );
}

function NoMatch() {
    let location = useLocation();

    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </div>
    );
}

export default withRoot(Index);
