import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import Button from "../components/Button";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import StoreIcon from "@material-ui/icons/Store";

const backgroundImage = "/static/images/hero.jpg";

const styles = (theme) => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        // backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundColor: "#443F3B", // Average color of the background image.
        backgroundPosition: "center",
        animationName: "fade-in",
        animationDuration: "1s",
        // animationDelay: "0.5s",
        animationTimingFunction: "ease-in",
        animationIterationCount: "1",
        opacity: "0",
    },
    visible: {
        opacity: "1",
    },
    button: {
        minWidth: 200,
    },
    h5: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(10),
        },
    },
    h1: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            fontSize: "50px",
        },
    },
    more: {
        marginTop: theme.spacing(2),
    },
});

function ProductHero(props) {
    const { classes } = props;

    useEffect(() => {
        // document.body.classList.add("modal-open");
        const elem = document.querySelector("." + `${classes.background}`);
        elem.classList.add(`${classes.visible}`);
    });

    return (
        <ProductHeroLayout backgroundClassName={classes.background}>
            <Typography
                color="inherit"
                align="center"
                variant="h2"
                marked="center"
                className={classes.h1}
                style={{ fontWeight: 900, color: "white" }}
            >
                Under construction
            </Typography>
            <SimpleCard />
            <Button
                color="secondary"
                variant="contained"
                size="large"
                className={classes.button}
                component="a"
                href="https://www.moaai.com"
            >
                Nasz sklep on-line
            </Button>
        </ProductHeroLayout>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        marginBottom: theme.spacing(4),
    },
    icon: {
        marginRight: "10px",
        color: theme.palette.text.secondary,
    },
}));

function SimpleCard() {
    const classes = useStyles();

    const [showEmail, setShowEmail] = useState(false);

    const MailTo = () => {
        return (
            <Typography className={classes.title} color="textSecondary">
                magdalena@moaai.com
            </Typography>
        );
    };

    const email = showEmail ? (
        <MailTo />
    ) : (
        <Button size="small" variant="outlined" onClick={() => setShowEmail(true)}>
            Kliknij aby uzyskać dane kontaktowe
        </Button>
    );

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Showroom
                </Typography>
                <Grid container direction="row" alignItems="center" style={{ minHeight: "39px" }}>
                    <StoreIcon className={classes.icon} />
                    <Typography className={classes.title} color="textSecondary">
                        ul. Bóżnicza 1/203, 61-751 Poznań, PL
                    </Typography>
                </Grid>
                <Grid container direction="row" alignItems="center" style={{ minHeight: "39px" }}>
                    <PhoneIcon className={classes.icon} />
                    <Typography className={classes.title} color="textSecondary">
                        +48 608 008 995
                    </Typography>
                </Grid>
                <Grid container direction="row" alignItems="center" style={{ minHeight: "39px" }}>
                    <MailIcon className={classes.icon} />
                    {email}
                </Grid>
            </CardContent>
        </Card>
    );
}

ProductHero.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
