import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar";
import AppBar from "@material-ui/core/AppBar";

import { Container, Typography, Grid, Paper } from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import ProductHero from "./ProductHero";

import Hamburger from "hamburger-react";

const logo = "/static/images/moaai.png";

// console.log(theme) || {
const styles = (theme) => ({
    root: {
        // backgroundColor: "red",
        "&:before": {
            content: '" "',
            position: "absolute",
            height: "100%",
            width: "100%",
            background: theme.palette.common.black,
            opacity: 0.3,
        },
    },
    toolbar: {
        paddingTop: "4%",
        // [theme.breakpoints.down("sm")]: {
        //     paddingTop: "8%",
        // },

    },
    title: {
        fontSize: 24,
    },
    barColor: {
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0)",
    },
    // logo: {
    //   flexGrow: 1,
    // },
    menuButton: {
        // marginRight: theme.spacing(2),
        marginLeft: "auto",
        padding: "0px",
    },
    listItemText: {
        fontSize: theme.typography.h5.fontSize,
        color: "#28282a",
        padding: 0,
        margin: 0,
        display: "inline-block",
        position: "inherit",
    },
    contentShift: {
        top: "0px !important",
        transitionDuration: "0.5s ease",
    },
    toolbarGutters: {
        padding: "0 2%",
        // paddingTop: "4%"
    },
    kurde: {
        backgroundColor: "#eee",
        position: "fixed",
        width: "100%",
        height: "100vh",
        zIndex: "200",
        top: "-1030px",
        transitionDuration: "0.5s",
        paddingTop: "20px",
        overflow: "scroll",
    },
    red: {
        color: "red",
        padding: "0px",
    },
    a: {
        "&::before": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "1px",
            left: "0",
            bottom: "-3px",
            opacity: "0",
            backgroundColor: "#ff3366",
            transition: ".3s ease transform,.3s ease opacity",
            transform: "scaleX(0)",
        },
        "&:hover::before": {
            opacity: "1",
            height: "1px",
            transform: "scale(1)",
        },
    },
    logo: {
        width: "128px",
        [theme.breakpoints.down("md")]: {
            width: "96px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "64px",
        },
    },
});

function AppAppBar(props) {
    const { classes } = props;

    // console.log(props);

    const [open, setOpen] = React.useState(false);

    const products = [
        { title: "Stoły i krzesła", url: "/tables" },
        { title: "Kanapy, fotele, pufy", url: "/produkty/kanapy-fotele-pufy/" },
        { title: "Zabudowa meblowa, regały, biblioteki", url: "/produkty/zabudowa-meblowa-regaly-biblioteki/" },
        { title: "Garderoby, szafy, komody, wieszaki", url: "/produkty/garderoby-szafy-komody-wieszaki/" },
        { title: "Tekstylia, tapety, dywany", url: "/produkty/tekstylia-tapety-dywany/" },
        { title: "Oświetlenie", url: "/produkty/oswietlenie/" },
        { title: "Akcesoria", url: "/produkty/akcesoria/" },
        { title: "Artykuły dekoracyjne", url: "/produkty/artykuly-dekoracyjne/" },
        { title: "Łazienki", url: "/produkty/lazienki/" },
        { title: "Pokoje dziecięce i młodzieżowe", url: "/produkty/pokoje-dzieciece-i-mlodziezowe/" },
        { title: "Sypialnie", url: "/produkty/sypialnie/" },
        { title: "Biuro", url: "/produkty/biuro/" },
        { title: "Wokół domu", url: "/produkty/wokol-domu/" },
        { title: "Zewnętrzna przestrzeń publiczna", url: "/produkty/zewnetrzna-przestrzen-publiczna/" },
    ];

    const handleMenuOpen = () => {
        setOpen(!open);

        setTimeout(() => {
            if (!open) {
                document.body.classList.add("modal-open");
            } else {
                document.body.classList.remove("modal-open");
            }
        }, 500);
    };

    return (
        <div>
            <div className={classes.root}>
                <AppBar
                    color="primary"
                    elevation={0}
                    position="absolute"
                    classes={{ colorPrimary: classes.barColor }}
                    className={classes.toolbar}
                >
                    <Toolbar classes={{ root: classes.toolbarGutters }}>
                        {/* <Typography variant="h2" className={classes.logo}>
                            Moaai */}
                        <img src={`${logo}`} className={classes.logo} />
                        {/* </Typography> */}
                        {/* <div className={classes.menuButton}>
                            <Hamburger direction="right" toggled={open} toggle={handleMenuOpen} />
                        </div> */}
                    </Toolbar>
                </AppBar>
                <div className={clsx({ [classes.contentShift]: open }, classes.kurde)}>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <List>
                                    <Typography variant="h5">O Nas</Typography>
                                    <ListItem className={classes.MuiListItem} component={Link} href="projektowanie">
                                        <ListItemText
                                            primary="Projektowanie"
                                            classes={{
                                                primary: clsx(classes.listItemText, classes.a),
                                                root: classes.listItemText,
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem className={classes.MuiListItem} component={Link} href="prasa">
                                        <ListItemText
                                            primary="Prasa"
                                            classes={{
                                                primary: clsx(classes.listItemText, classes.a),
                                                root: classes.listItemText,
                                            }}
                                        />
                                    </ListItem>
                                </List>
                                <List>
                                    <Typography variant="h5">Produkty</Typography>
                                    {products.map((elem) => {
                                        return (
                                            <ListItem
                                                className={classes.MuiListItem}
                                                component={Link}
                                                href={elem.url}
                                                key={elem.title}
                                            >
                                                <ListItemText
                                                    primary={elem.title}
                                                    classes={{
                                                        primary: clsx(classes.listItemText, classes.a),
                                                        root: classes.listItemText,
                                                    }}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <List>
                                    <Typography variant="h5">Informacje</Typography>
                                    <ListItem className={classes.MuiListItem} component={Link} href="/nowosci/">
                                        <ListItemText
                                            primary="Nowości"
                                            classes={{
                                                primary: clsx(classes.listItemText, classes.a),
                                                root: classes.listItemText,
                                            }}
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
            <ProductHero />
        </div>
    );
}

AppAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
